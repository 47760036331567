<template lang="pug">
ModalsActionModal(@close="closeModal()")
	template(v-slot:header)
		template(v-if="songRequestStage === SongRequestStage.CONFIRM")
			p.modal-card-title {{ t('modal.requestCta') }}
			p.modal-card-subtitle {{ t('modal.requestCta.subtitle') }}

	template(v-slot:body)
		form(@submit.prevent="sendRequest")
			.modal-form-body(v-if="songRequestStage === SongRequestStage.CONFIRM")
				SingaField(:label="t('modal.songName')" horizontal :variant="songErrorMessage ? 'danger' : ''" :message="songErrorMessage")
					SingaInput(ref="songInputElem" rounded v-model="songName" :placeholder="t('modal.songName')" @input="songErrorMessage = ''")

				SingaField(:label="t('artists.artist.title')" horizontal :variant="artistErrorMessage ? 'danger' : ''" :message="artistErrorMessage")
					SingaInput(rounded v-model="artistName" :placeholder="t('artists.artist.title')" @input="artistErrorMessage = ''")

			.modal-form-body(v-else-if="songRequestStage === SongRequestStage.SONG_FOUND")
				p.modal-card-title {{ t('modal.songFound') }}
				p.subtext {{ t('modal.songFound.subtitle') }}
				SongListItem(:song="foundSong" :closeAfterAction="true")

			.center(v-else)
				SingaIcon.icon.success(icon="checkmark-circle-outline" size="large")
				p.modal-card-title {{ t('modal.requestThanks') }}
				p.subtext {{ t('modal.requestThanks.subtitle') }}

			.modal-form-footer(v-if="songRequestStage === SongRequestStage.CONFIRM")
				SingaSubmitButton.is-regular.is-primary.is-full-width(:isLoading="false" native-type="submit" :buttonCTA="t('modal.button.requestCta')")
</template>

<script setup lang="ts">
const { $singaApi } = useNuxtApp()
const songName = ref()
const artistName = ref()
const songErrorMessage = ref('')
const artistErrorMessage = ref('')
const foundSong = ref()
const { t } = useI18n()
const songInputElem = ref(null as any)

enum SongRequestStage {
	CONFIRM = 'Confirm',
	SONG_FOUND = 'Song found',
	REQUEST_SENT = 'Request sent'
}

const songRequestStage = ref(SongRequestStage.CONFIRM)
const isLoading = ref(false)

const closeModal = () => {
	songRequestStage.value = SongRequestStage.CONFIRM
	songName.value = ''
	artistName.value = ''
}

const validateRequest = () => {
	if (!songName?.value && !artistName?.value) {
		songErrorMessage.value = t('general.errors.required')
		artistErrorMessage.value = t('general.errors.required')
		return false
	} else if (!songName?.value) {
		songErrorMessage.value = t('general.errors.required')
		return false
	} else if (!artistName?.value) {
		artistErrorMessage.value = t('general.errors.required')
		return false
	} else {
		return true
	}
}

const sendRequest = async () => {
	const { contentMarket } = useGeoLocationStore()
	isLoading.value = true
	const validated = validateRequest()
	if (validated) {
		const { data: response, error } = await $singaApi.Songs.post({ song: songName.value, artist: artistName.value })

		if (error.value) {
			console.log(error)
		} else if (response.value.song_obj === null || !response.value.song_obj.available_markets.includes(contentMarket.market)) {
			songRequestStage.value = SongRequestStage.REQUEST_SENT
		} else {
			foundSong.value = response.value.song_obj
			songRequestStage.value = SongRequestStage.SONG_FOUND
		}
		isLoading.value = false
	}
}

onMounted(() => {
	songInputElem.value.focus()
})
</script>

<style lang="sass" scoped>
.icon.success
	width: 78px
	height: 78px
	color: $primary

.subtext
	padding-top: $spacing-16
	padding-bottom: $spacing-16
.center
	text-align: center
	margin: 0 auto
</style>
